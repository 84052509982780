/* Horizontal Line Style */
.hr-line::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 1px;
  content: " ";
  background: linear-gradient(90deg, #fff 0%, #d6d6d6 50%, #fff);
}

/* Drawer Mask Style */
.drawer-mask {
  opacity: 0; /* Initially hidden */
  width: 100%;
  height: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  transition: opacity 0.3s cubic-bezier(.78,.14,.15,.86), height 0s ease .3s;
}

/* Category Slider Buttons (Prev/Next) */
.category-slider button.prev {
  background-color: #10b981;
}

.swiper-button-next:after, .swiper-button-prev:after {
  background-color: #10b981;
  color: #fff !important;
  border-radius: 3px !important;
  font-size: 100% !important;
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-next {
  right: 0px !important;
}

.swiper-button-prev {
  left: 0px !important;
}

/* Mobile Drawer Width for Small Screens */
@media (max-width: 480px) {
  .rs-drawer-left.rs-drawer-xs,
  .rs-drawer-right.rs-drawer-xs {
    width: 100% !important; /* Full width for small screens */
  }
}

/* Full Height for All Devices */
.full-height {
  min-height: 100vh; /* Ensure full height coverage */
}

/* Target Smaller Screens (Mobile, Tablets) */
@media only screen and (max-width: 768px) {
  .full-height {
    min-height: 100vh; /* Keeps the full height on mobile and tablet */
  }

  /* Optional: Add more specific adjustments for smaller screens here */
  .category-slider button.prev {
    font-size: 14px; /* Adjust size for mobile view */
  }

  /* Adjust Swiper Button size for smaller screens */
  .swiper-button-next:after, .swiper-button-prev:after {
    font-size: 16px; /* Smaller size for buttons */
    width: 25px;  /* Smaller size for mobile screens */
    height: 25px; /* Smaller size for mobile screens */
  }

  /* Adjust Drawer Mask for mobile */
  .drawer-mask {
    transition: opacity 0.3s ease, height 0s ease .3s;
  }

  /* Add padding/margin adjustments for mobile or tablet */
  .some-element {
    padding: 10px;  /* Example padding for mobile */
    margin: 10px;   /* Example margin for mobile */
  }
}

/* Target Larger Screens (Tablet and Desktop) */
@media only screen and (min-width: 769px) {
  /* You can add additional styles for tablets and desktops here */
  .category-slider button.prev {
    font-size: 18px; /* Larger size for tablet and desktop view */
  }

  .swiper-button-next:after, .swiper-button-prev:after {
    font-size: 18px;  /* Larger button size */
    width: 30px;  /* Larger size for tablets and desktops */
    height: 30px; /* Larger size for tablets and desktops */
  }

  /* Ensure that the drawer mask is functional in desktop view */
  .drawer-mask {
    opacity: 1;  /* Make it visible in desktop view */
    height: 100%; /* Full height for the mask */
  }
}
